import React from 'react'
import {
    Box,
    Stack
} from '@mui/material'
import * as Images from '../../components/UIComponents/Images'
import * as Buttons from '../../components/UIComponents/Button'
import * as Texts from '../../components/UIComponents/Text'

const { innerWidth } = window

const FAQBotOnboarding = ({
    onClick,
    headerText,
    descriptionText,
    call2ActionText
}) => {
    return (
        <Stack
            direction="column"
            justifyContent={"center"}
            spacing={{
                xs: 4,
                sm: 6,
                md: 10
            }}
            sx={{
                pt: 6,
                px: 6
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Images.ChatbotOnboarding />
            </Box>
            <Stack
                direction="column"
                justifyContent={"center"}
                spacing={{
                    xs: 4
                }}
                sx={{
                    textAlign: 'center'
                }}
            >
                <Texts.H4>{headerText}</Texts.H4>
                <Texts.Text>{descriptionText}</Texts.Text>
            </Stack>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Buttons.Button
                    onClick={onClick}
                >
                    <Texts.H4 sx={{ color: "primary.contrastText" }}>{call2ActionText}</Texts.H4>
                </Buttons.Button>
            </Box>
            {/*<Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Texts.Paragraph>What is Ms. OICI?</Texts.Paragraph>
            </Box>*/}
        </Stack>
    )

}
export default FAQBotOnboarding