import React, { Component, useMemo } from 'react'
import { bindActionCreators } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import methods from '../reducers/methods'
import * as ConfigActions from '../reducers/config'
import * as UserActions from '../reducers/user'
import CustomTheme from '../components/CustomTheme'
import {
    BrowserRouter as Router,
    Route,
    Link,
    Switch
} from "react-router-dom"
import { withRouter } from "react-router"
// import SwipeableViews from 'react-swipeable-views'
import { IonRouterOutlet, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import {
    Grid,
    Paper,
    Typography,
    Drawer,
    Toolbar,
    Divider,
    Box,
    Stack,
    CssBaseline,
    List,
    ListItem
} from '@mui/material'
import AppNavigator from '../components/AppNavigator'
import * as Buttons from '../components/UIComponents/Button'
import * as Images from '../components/UIComponents/Images'
import * as Texts from '../components/UIComponents/Text'
import SwitchButtons from './Onboarding/SelectBot'
import NurseOnboarding from './Onboarding/NurseOnboarding'
import FAQBotOnboarding from './Onboarding/FAQBotOnboarding'
import BotHelper from '../helpers/BotHelper'
import TypingAnimation from '../components/UIComponents/TypingAnimation'
import { v4 as uuidv4 } from 'uuid'

const { innerWidth, innerHeight } = window

class Onboarding extends Component {
    state = {
        index: 0,
    }

    async componentDidMount()
    {
        // console.log(this.props)
        const { config, user, login, switchLanguage, resetSession } = this.props
        const { dict, lang } = config
        resetSession()

        // better the load the language every time logging into the app
        switchLanguage(lang)
        // clean the previous session and create a new one

    }

    handleChangeIndex = index => {
        this.setState({
            index,
        })
    }

    render() {
        const { index } = this.state
        // console.log("props", this.props)
        const { history, config, user, login } = this.props
        const { dict, bot } = config
        const lang = dict['welcome'][bot]
        // console.log("language", lang)

        return (<CustomTheme>
            <Router>
                <Box
                    sx={{
                        height: innerHeight,
                        bgcolor: index === 0 ? 'background.gray':'background.highlight',
                        // display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p:0
                        // pt: 4
                    }}
                    style={{
                        // paddingTop: `env(safe-area-inset-top)`,
                        // paddingLeft: `env(safe-area-inset-left)`,
                        // paddingRight: `env(safe-area-inset-right)`,
                        // paddingBottom: `env(safe-area-inset-bottom)`,
                    }}
                >
                    {/*<Grid item
                        sx={{
                            p: 0
                        }}
                    >
                        <SwitchButtons 
                            index={index} 
                            changeIndex={this.handleChangeIndex}
                        />
                    </Grid>*/}
                    <Grid container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        // spacing={3}
                        // columnSpacing={3}
                        sx={{
                            // bgcolor: index === 0 ? 'background.gray':'background.highlight',
                            pb: 7,
                            ml: "auto",
                            mr: "auto",
                            maxWidth: 600
                        }}
                    >
                        <Grid item
                            xs={8}
                            sm={6}
                            md={6}
                            lg={6}
                            sx={{
                                // flex: 1,
                                m: 'auto',
                                p: 0,
                                // bgcolor: 'background.highlight',
                                // height: 200,
                                // width: 1
                            }}
                        >
                            {/*<SwipeableWrapper 
                                index={index} 
                                onChangeIndex={this.handleChangeIndex}
                                enterFAQBotRoom={() => history.push("chatroom/faqbot")}
                                enterNurseRoom={() => history.push("chatroom/nurse")}
                            />*/}
                            {<FAQBotOnboarding 
                                headerText={lang['How did we do?']}
                                descriptionText={lang['Chat with Anketo Bot to share your thoughts and concerns about the visit.']}
                                call2ActionText={lang['調査']}
                                onClick={async () => {
                                    const userId = uuidv4()
                                    const questionSessionId = uuidv4()
                                    await login({
                                        id: userId,
                                        sessionId: questionSessionId
                                    })
                                    await history.push("chatroom/faqbot")
                                }}
                            />}
                            {/*<TypingAnimation dotWidth={8} fill={"#888888"} />*/}
                            {/*<Switch>
                                <Route path="/nurse">
                                    <SwipeableWrapper />
                                </Route>
                                <Route path="/faqbot">
                                    <FAQBot />
                                </Route>
                                <Route path="/">
                                    <SwipeableWrapper />
                                </Route>
                            </Switch>*/}
                        </Grid>
                    </Grid>
                </Box>


            </Router>
        </CustomTheme>)
    }
}
/** deprecated
const SwipeableWrapper = ({
    index,
    onChangeIndex,
    enterNurseRoom,
    enterFAQBotRoom
}) => {
    return (<SwipeableViews index={index} onChangeIndex={onChangeIndex}>
        <NurseOnboarding 
            onClick={enterNurseRoom}
        />
        <FAQBotOnboarding 
            onClick={enterFAQBotRoom}
        />
    </SwipeableViews>)
}
*/

let OnboardingContainer = props => {
    const { dispatch, user, config } = props


    const boundActionCreators = useMemo(() => bindActionCreators({
        ...UserActions,
        ...ConfigActions,
        resetSession: methods.RESET_SESSION
    }, dispatch), [dispatch])

    return <Onboarding user={user} config={config} {...boundActionCreators} {...props} />
}

OnboardingContainer = connect(state => ({ user: state.user, config: state.config }))(OnboardingContainer)

export default withRouter(OnboardingContainer)