import { createSlice, createAction } from '@reduxjs/toolkit'

const initUser = {
    id: '',
    sessionId: ''
}

const userReducer = createSlice({
    name: 'user',
    initialState: initUser,
    reducers: {
        login: (state, action) => {
            // console.log("received action", action)
            state.id = action.payload.id
            state.sessionId = action.payload.sessionId
            // return action.payload
            return
        },
        update: state => {

        },
        logout: state => {
            return initUser
        }
    },
    extraReducers: (builder) => {
        builder
          .addCase(createAction("RESET_REDUCER"), (state, action) => {
            // console.log("user reducer receives action", action)
            state=initUser
          })
          .addDefaultCase((state, action) => {
            state = initUser
          })
    },
})
  
// Action creators are generated for each case reducer function
export const { login, update, logout } = userReducer.actions

export default userReducer.reducer