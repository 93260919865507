import * as React from 'react';
import { Box } from '@mui/material'
import InputBase from '@mui/material/InputBase'
import CustomTheme from './CustomTheme';

const CustomInput = React.forwardRef(function CustomInput(props, ref) {
  return (
    <InputBase {...props} ref={ref} fullWidth={true}
        sx={{
            // border:1,
            // borderRadius: 4,
            bgcolor: 'primary.contrastText',
            color: 'primary.main',
            px: 2,
        }}
    />);
});

const ChatInput = (props) => {
  return <CustomTheme>
    <Box
        sx={{
            pl: 2,
            py: 2,
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
        }}
    >
    <CustomInput aria-label="Demo input" placeholder="What's in your mind…" {...props} />
    </Box>
  </CustomTheme>;
}

export default ChatInput