import React, { Component, useState, useEffect } from 'react'
import {
    Grid,
    Typography,
    Drawer,
    Toolbar,
    Divider,
    Box,
    Stack,
    CssBaseline,
    List,
    ListItem
} from '@mui/material'
import * as Loaders from '../../components/UIComponents/Loader'
import * as Icons from '../../components/UIComponents/Icons'
import MUIIconButton from '@mui/material/IconButton'
import ChatInput from '../../components/ChatInput'
import config from '../../config/config'

const SendPanel = ({
    sendMessage,
    onMessageSent
}) => {
    const [message, updateMessage] = useState("")
    const [loading, setLoading] = useState(false)

    return (<React.Fragment>
        <Box
            sx={{
                position: 'absolute',
                bottom: 0,
                width: 1,
                bgcolor: 'primary.contrastText'
                // paddingTop: `env(safe-area-inset-top)`,
                // paddingLeft: `env(safe-area-inset-left)`,
                // paddingRight: `env(safe-area-inset-right)`,
                // paddingBottom: `env(safe-area-inset-bottom)`,
            }}
        >
        <Grid container direction={'row'} justify={"center"} align={"center"} sx={{
            // position: 'absolute',
            // maxHeight: {
            //     xs: config.sendPanelHeight,
            //     sm: config.sendPanelHeight,
            //     md: config.sendPanelHeight
            // },
            // minHeight: config.sendPanelHeight,
            // px: {
            //     xs: 0
            // },
            mb: 0,
            bottom: 0,
            boxShadow: 3,
            pb: 4,
            pt: 3
        }}
        >
            <Grid item xs sm md sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                // px: 1,
                // maxHeight: 64,
                my: 'auto'
            }}>
                <form
                    style={{
                        width: '100%'
                    }}
                    onSubmit={(e) => {
                        e.preventDefault()
                        sendMessage(message)
                        updateMessage("")
                    }}
                >
                    <ChatInput
                        value={message}
                        type={"text"}
                        placeholder={"ご自由にご記入ください。"}
                        onChange={e => {
                            // console.log(e)
                            updateMessage(e.target.value)
                        }}
                    />
                </form>
            </Grid>
            <Grid item
                sx={{
                    // width: 32,
                    my: 'auto'
                }}
            >
                <MUIIconButton color="primary" aria-label="send the supportive message"
                    sx={{
                        height: 48,
                        width: 48
                    }}
                    onClick={() => {
                        sendMessage(message)
                        updateMessage("")
                    }}
                >
                    <Icons.SendIcon2 style={{ width: '100%' }} />
                </MUIIconButton>
            </Grid>
        </Grid>
        </Box>
    </React.Fragment>)
    
}

export default SendPanel