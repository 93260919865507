import React from 'react'
import AvatarImage from '../../assets/images/Avatar.svg'
import NurseAvatarImage from '../../assets/images/Nurse_avatar.png'
import ChatbotOnboardingImage from '../../assets/images/Chatbot_onboarding.svg'
import NurseOnboardingImage from '../../assets/images/Nurse_onboarding.svg'
import NursePointerRightImage from '../../assets/images/Nurse_pointer_right.svg'
import ChatbotPointerLeftImage from '../../assets/images/Chatbot_pointer_left.svg'
import ChatbotPointerRightImage from '../../assets/images/Chatbot_pointer_right.svg'



const Avatar = props => (
    <img
        {...props}
        src={AvatarImage}
        alt="avatar-image" 
    />
)

const NurseAvatar = props => (
    <img
        {...props}
        src={NurseAvatarImage}
        alt="nurse-avatar-image" 
    />
)

const ChatbotOnboarding = props => (
    <img
        {...props}
        src={ChatbotOnboardingImage}
        alt="chatbot-onboarding-image" 
    />
)

const NurseOnboarding = props => (
    <img
        {...props}
        src={NurseOnboardingImage}
        alt="nurse-onboarding-image" 
    />
)


const ChatbotPointerLeft = props => (
    <img
        {...props}
        src={ChatbotPointerLeftImage}
        alt="chatbot-pointer-left-image" 
    />
)

const ChatbotPointerRight = props => (
    <img
        {...props}
        src={ChatbotPointerRightImage}
        alt="chatbot-pointer-right-image" 
    />
)


const NursePointerRight = props => (
    <img
        {...props}
        src={NursePointerRightImage}
        alt="nurse-pointer-right-image"
        style={{
            width: '48px',
            height: '48px'
        }}
    />
)



export {
    Avatar,
    NurseAvatar,
    ChatbotOnboarding,
    NurseOnboarding,
    ChatbotPointerLeft,
    ChatbotPointerRight,
    NursePointerRight
}