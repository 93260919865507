import React, { useState } from 'react'
import {
    Grid,
    ListItemButton,
    ListItemText,
    ListItemIcon
} from '@mui/material'
import MUIButton from '@mui/material/Button'
import CustomTheme from '../CustomTheme'
import MUITab from '@mui/material/Tab'
import MUITabs from '@mui/material/Tabs'
import * as Icons from './Icons'
import * as Texts from './Text'
import MUIIconButton from '@mui/material/IconButton'

const Tabs = props => (<CustomTheme>
    <MUITabs {...props}
        textColor="primary"
        indicatorColor="primary"
        aria-label="primary tabs"
    />
</CustomTheme>)

const IconTab = (props) => (<CustomTheme>
    <MUITab {...props}
        icon={
            <Grid item sx={{
                width: {
                    xs: '36px',
                    sm: '36px',
                    md: '64px'
                },
                ml: 'auto',
                mr: 'auto'
            }}>
                {props.icon}
            </Grid>
        }
    />
</CustomTheme>
)

const Button = (props) => {
    // console.log(props)
    return (
        <CustomTheme>
            <MUIButton
                // className={props.className ? `fyw-button px-4 ${props.className}` : `fyw-button px-4`} 
                className={props.className}
                type={props.type || 'button'}
                onClick={props.onClick ? (e) => props.onClick(e) : null}
                variant="contained"
                color="primary"
                {...props}
                sx={{
                    borderRadius: 10,
                    px: 6,
                    py: 2,
                    ...props.sx
                }}
            // style={props.style || {}}
            >
                {props.children}
            </MUIButton>
        </CustomTheme>
    )
}
/**
 * example
    <Buttons.Icon 
        icon={<Icons.CancelIcon 
            width="8"
            height="8"
        />}
        sx={{
            position: 'absolute',
            top: 4,
            right: 4
        }}
    />
 */
const Icon = (props) => {
    return (
        <MUIIconButton {...props} >
            {props.icon}
        </MUIIconButton>
    )
}

const IconButton = (props) => (
    <Button {...props} variant="contained" endIcon={props.icon}>
        {props.title}
    </Button>
    // return (
    //     <button className={props.className ? `fyw-button ${props.className}`:`fyw-button`} type={props.type} onClick={(e) => props.onClick(e)} style={props.style || null}>
    //         <i className={props.btnClassName ? `fyw-button-icon ${props.btnClassName}` : `fyw-button-icon`}></i>
    //         {props.children}
    //     </button>
    // )
)

const OutlinedButton = (props) => (
    <Button
        {...props}
        variant="contained"
        sx={{
            bgcolor: props.checked ? 'primary.main' : 'background.default',
            // border: 'primary.main',
            border: 1,
            borderColor: 'primary.main',
            color: props.checked ? "primary.contrastText" : "primary.main",
            p: 1,
            px: 2,
            ...props.sx
        }}
    >
        <Texts.Paragraph
            sx={{
                color: "inherit"
            }}
        >{props.title}</Texts.Paragraph>
    </Button>
)

const CheckButton = (props) => {
    // console.log(`is checked for ${props.title} ${props.checked}`)
    
    return (
        <Button
            variant={"outline"}
            startIcon={props.checked ? <Icons.CheckedBox /> : <Icons.UncheckedBox />}
            {...props}
            sx={{
                bgcolor: props.checked ? 'primary.main' : 'background.default',
                // border: 'primary.main',
                border: 1,
                borderColor: 'primary.main',
                color: props.checked ? 'background.default' : "primary.main",
                p: 1,
                px: 2,
                '&:active': {
                    boxShadow: 1,
                    bgcolor: 'primary.main',
                    color: 'primary.contrastText'
                },
                '&:focus': {
                    boxShadow: 1,
                    bgcolor: 'primary.main',
                    color: 'primary.contrastText'
                },
                ...props.sx
            }}

        >
            <Texts.Paragraph
                sx={{
                    color: "inherit"
                }}
            >{props.title}</Texts.Paragraph>
        </Button>
    )
}

const DrawerButton = props => (<CustomTheme>
    <ListItemButton
        {...props}
        sx={{
            color: 'primary.dark',
            bgcolor: 'Background.default',
            '&:hover': {
                backgroundColor: 'background.highlight',
            },
            '&.Mui-selected:hover': {
                backgroundColor: 'background.highlight'
            },
            '&:focus': {
                backgroundColor: 'background.highlight'
            },
            '&.Mui-selected': {
                backgroundColor: 'background.highlight'
            }
        }}
    >
        <ListItemIcon>
            {props.icon ? props.icon : <Icons.HomeIcon />}
        </ListItemIcon>
        <ListItemText primary={props.text || "Home"} />
    </ListItemButton>
</CustomTheme>
)


export { IconTab, Tabs, Button, Icon, IconButton, DrawerButton, OutlinedButton, CheckButton }