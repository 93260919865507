import PS2Logo from '../../assets/icons/Logo.svg'
import Id from '../../assets/icons/id.gif'
import Story from '../../assets/icons/book.gif'
import Messenger from '../../assets/icons/social-media.gif'
import HugsCount from '../../assets/icons/hug.svg'
import SupportMsg from '../../assets/icons/message.svg'
import SupportMsg2 from '../../assets/icons/message2.svg'
import Send from '../../assets/icons/send.svg'
import Hugged from '../../assets/icons/hugged.svg'
import Connected from '../../assets/icons/connected.svg'
import Delete from '../../assets/icons/delete.svg'
import Logout from '../../assets/icons/logout.svg'
import Home from '../../assets/icons/home.svg'
import LogoWhiteImage from '../../assets/icons/Logo_white.svg'
import FormIconImage from '../../assets/icons/form_icon.svg'
import SendChat2 from '../../assets/icons/send_chat_2.svg'

const FormIcon = props => <img {...props} src={FormIconImage} alt="form-icon" />

const Logo = (props) => <img {...props} src={PS2Logo} alt="app-logo" />
const LogoWhite = props => <img {...props} src={LogoWhiteImage} alt="app-logo-white" />
const LogoutIcon = props => <img {...props} src={Logout} alt="logout-icon" />
const HomeIcon = props => <img {...props} src={Home} alt="home-icon" />

const ProfileCardIcon = (props) => <img {...props} src={Id} alt="profile-tab-icon" />
const StoryIcon = props => <img {...props} src={Story}  alt="story-tab-icon" />
const MessengerIcon = props => <img {...props} src={Messenger}  alt="messenger-tab-icon" />

const HugsCountIcon = props => <img {...props} src={HugsCount}  alt="hugs-count-icon" />
const SupportMsgIcon1 = props => <img {...props} src={SupportMsg}  alt="support-message-1-icon" />
const SupportMsgIcon2 = props => <img {...props} src={SupportMsg2}  alt="support-message-2-icon" />

const HuggedCountIcon = props => <img {...props} src={Hugged}  alt="hugged-icon" />
const ConnectedIcon = props => <img {...props} src={Connected}  alt="connected--icon" />

const SendIcon = props => <img {...props} src={Send}  alt="send-icon" />
const DeleteIcon = props => <img {...props} src={Delete}  alt="delete-icon" />
const SendIcon2 = props => <img {...props} src={SendChat2}  alt="send-icon-2" />

const BackIcon = props => <svg width="24" height="24" viewBox="0 0 24 24" fill={props.color || "white"} xmlns="http://www.w3.org/2000/svg">
<path d="M7.76472 12.0055L18.1941 1.57622C18.5546 1.21561 18.5546 0.630979 18.1941 0.270373C17.8334 -0.0901245 17.2488 -0.0901245 16.8882 0.270373L5.80596 11.3526C5.44546 11.7132 5.44546 12.2979 5.80596 12.6585L16.8882 23.7407C17.2551 24.0951 17.8397 24.0849 18.1941 23.718C18.5397 23.3601 18.5397 22.7927 18.1941 22.4349L7.76472 12.0055Z" fill={props.color || "white"} />
</svg>

const CancelIcon = props => <svg width={props.width || "8"} height={props.height || "8"} viewBox="0 0 8 8" fill={props.color || "#003893"} xmlns="http://www.w3.org/2000/svg">
<path d="M4.73302 4.00006L7.84812 0.884861C8.05085 0.68223 8.05085 0.354604 7.84812 0.151973C7.64549 -0.0506577 7.31786 -0.0506577 7.11523 0.151973L4.00004 3.26717L0.884932 0.151973C0.682206 -0.0506577 0.354675 -0.0506577 0.152044 0.151973C-0.0506814 0.354604 -0.0506814 0.68223 0.152044 0.884861L3.26715 4.00006L0.152044 7.11526C-0.0506814 7.31789 -0.0506814 7.64551 0.152044 7.84815C0.253027 7.94922 0.385805 8 0.518488 8C0.651171 8 0.783854 7.94922 0.884932 7.84815L4.00004 4.73295L7.11523 7.84815C7.21631 7.94922 7.349 8 7.48168 8C7.61436 8 7.74704 7.94922 7.84812 7.84815C8.05085 7.64551 8.05085 7.31789 7.84812 7.11526L4.73302 4.00006Z" fill={props.color || "#003893"}/>
</svg>

const UncheckedBox = props => <svg width={props.width || "16"} height={props.height || "16"} viewBox="0 0 16 16" fill={props.color || "#003893"} xmlns="http://www.w3.org/2000/svg">
<path d="M12.6666 1.33337H3.33331C2.80288 1.33337 2.29417 1.54409 1.9191 1.91916C1.54403 2.29423 1.33331 2.80294 1.33331 3.33337V12.6667C1.33331 13.1971 1.54403 13.7058 1.9191 14.0809C2.29417 14.456 2.80288 14.6667 3.33331 14.6667H12.6666C13.1971 14.6667 13.7058 14.456 14.0809 14.0809C14.4559 13.7058 14.6666 13.1971 14.6666 12.6667V3.33337C14.6666 2.80294 14.4559 2.29423 14.0809 1.91916C13.7058 1.54409 13.1971 1.33337 12.6666 1.33337ZM13.3333 12.6667C13.3333 12.8435 13.2631 13.0131 13.1381 13.1381C13.013 13.2631 12.8435 13.3334 12.6666 13.3334H3.33331C3.1565 13.3334 2.98693 13.2631 2.86191 13.1381C2.73688 13.0131 2.66665 12.8435 2.66665 12.6667V3.33337C2.66665 3.15656 2.73688 2.98699 2.86191 2.86197C2.98693 2.73695 3.1565 2.66671 3.33331 2.66671H12.6666C12.8435 2.66671 13.013 2.73695 13.1381 2.86197C13.2631 2.98699 13.3333 3.15656 13.3333 3.33337V12.6667Z" fill={props.color || "#003893"}/>
</svg>

const CheckedBox = props => <svg width={props.width || "16"} height={props.height || "16"} viewBox="0 0 16 16" fill={props.color || "white"} xmlns="http://www.w3.org/2000/svg">
<path d="M12.6667 1.33337H3.33337C2.80294 1.33337 2.29423 1.54409 1.91916 1.91916C1.54409 2.29423 1.33337 2.80294 1.33337 3.33337V12.6667C1.33337 13.1971 1.54409 13.7058 1.91916 14.0809C2.29423 14.456 2.80294 14.6667 3.33337 14.6667H12.6667C13.1971 14.6667 13.7058 14.456 14.0809 14.0809C14.456 13.7058 14.6667 13.1971 14.6667 12.6667V3.33337C14.6667 2.80294 14.456 2.29423 14.0809 1.91916C13.7058 1.54409 13.1971 1.33337 12.6667 1.33337ZM13.3334 12.6667C13.3334 12.8435 13.2631 13.0131 13.1381 13.1381C13.0131 13.2631 12.8435 13.3334 12.6667 13.3334H3.33337C3.15656 13.3334 2.98699 13.2631 2.86197 13.1381C2.73695 13.0131 2.66671 12.8435 2.66671 12.6667V3.33337C2.66671 3.15656 2.73695 2.98699 2.86197 2.86197C2.98699 2.73695 3.15656 2.66671 3.33337 2.66671H12.6667C12.8435 2.66671 13.0131 2.73695 13.1381 2.86197C13.2631 2.98699 13.3334 3.15656 13.3334 3.33337V12.6667ZM11.78 4.85337C11.9042 4.97828 11.9739 5.14725 11.9739 5.32337C11.9739 5.4995 11.9042 5.66847 11.78 5.79337L6.80671 11.0867C6.74397 11.1525 6.66843 11.2048 6.58474 11.2403C6.50104 11.2759 6.41097 11.2939 6.32004 11.2934C6.22912 11.2939 6.13904 11.2759 6.05534 11.2403C5.97165 11.2048 5.89611 11.1525 5.83337 11.0867L4.20671 9.33337C4.1392 9.27149 4.08516 9.19637 4.04797 9.11268C4.01078 9.02899 3.99123 8.93854 3.99054 8.84697C3.98984 8.75539 4.00803 8.66465 4.04395 8.58041C4.07988 8.49618 4.13277 8.42024 4.19934 8.35735C4.2659 8.29446 4.34471 8.24595 4.43085 8.21486C4.51699 8.18377 4.60861 8.17076 4.7 8.17664C4.79139 8.18252 4.88059 8.20717 4.96203 8.24905C5.04348 8.29093 5.11542 8.34913 5.17337 8.42004L6.34671 9.66671L10.8334 4.89337C10.8927 4.8282 10.9643 4.77542 11.0441 4.7381C11.1239 4.70078 11.2103 4.67964 11.2984 4.67593C11.3864 4.67221 11.4743 4.68597 11.557 4.71643C11.6396 4.74689 11.7155 4.79343 11.78 4.85337Z" fill={props.color || "white"}/>
</svg>


export {
    FormIcon,
    Logo,
    LogoutIcon,
    HomeIcon,
    LogoWhite,
    ProfileCardIcon,
    StoryIcon,
    MessengerIcon,
    HugsCountIcon,
    SupportMsgIcon1,
    SupportMsgIcon2,
    SendIcon,
    HuggedCountIcon,
    ConnectedIcon,
    DeleteIcon,
    SendIcon2,
    BackIcon,
    CancelIcon,
    UncheckedBox,
    CheckedBox
}