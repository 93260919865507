import React, { useRef, useEffect } from 'react'

const TypingAnimation = ({
    dotWidth = 8,
    fill = "#000000"
}) => {
    let minUnit = dotWidth, containerWidth = minUnit * 7, containerHeight = minUnit * 5
    const cLeft = useRef(null)
    const cCenter = useRef(null)
    const cRight = useRef(null)
    useEffect(() => {

        let currentAnimationTime = 0;
        const centreY = containerHeight / 2;
        const amplitude = 10;
        // console.log("cleft", cLeft)

        function animate() {
            // console.log("cLeft", cCenter)
            try {
                cLeft.current.setAttribute('cy',
                    centreY + (amplitude * (Math.sin(currentAnimationTime))));
                cCenter.current.setAttribute('cy',
                    centreY + (amplitude * (Math.sin(currentAnimationTime - 1))));
                cRight.current.setAttribute('cy',
                    centreY + (amplitude * (Math.sin(currentAnimationTime - 2))));
                currentAnimationTime += 0.15;
                requestAnimationFrame(animate);
            }
            catch(e)
            {

            }
        }
        animate();

    }, [])
    return (<svg width={containerWidth} height={containerHeight} fill={fill}>
        <circle ref={cLeft} cx={minUnit * 1.5} cy={minUnit * 2} r={minUnit / 1.5} />
        <circle ref={cCenter} cx={minUnit * 3.5} cy={minUnit * 2} r={minUnit / 1.5} />
        <circle ref={cRight} cx={minUnit * 5.5} cy={minUnit * 2} r={minUnit / 1.5} />
    </svg>)
}

export default TypingAnimation