import { combineReducers } from 'redux'
import { createSlice, createAction } from '@reduxjs/toolkit'
import appConfig from './config'
import user from './user'
import survey from './survey'
// import todos from './todos'
// import counter from './counter'

// console.log("imported", appConfig)
const appReducer = combineReducers({
    config: appConfig,
    user,
    survey
})

const rootReducer = (state, action) => {
    // console.log("received action", action)
    if (action.type === 'RESET_SESSION') {
        // console.log("should reset", action)
        // console.log(appReducer(undefined, action))
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}

export default rootReducer